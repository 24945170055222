<template>
  <div class="admin-edit-template" v-if="!loading">
    <div class="preview-container">
      <h3>Template Preview</h3>
      <div class="green-divider"></div>
      <FormTemplate :template="template"></FormTemplate>
    </div>

        <div class="flex-row">
          <div class="col-12">
            <label for="name" >Name</label>
            <input type="text" v-model="template.name" class="w-100"
                   maxlength="256" name="name" data-name="name" placeholder="Template Name" id="name"
                   @input="isDirty=true"
            />
          </div>
          <div class="col-12">
            <label for="description" >Description</label>
            <input type="text" v-model="template.description" class="w-100"
                   maxlength="256" name="description" data-name="description" placeholder="Template Description"
                   id="description" @input="isDirty=true"
            />
          </div>
          <div class=" col-4 s4">
            <label for="template_id">Template ID</label>
            <input type="text" v-model="template.template_id" class="w-100" disabled
                   maxlength="256" placeholder="Template Id" id="template_id">
          </div>
          <div class=" col-4 s4">
            <label for="rev_number">Revision Number</label>
            <input type="text" v-model="template.revision_number" class="w-100" disabled
                   maxlength="256" placeholder="Revision Number" id="rev_number">
          </div>
          <div class="col-4">
            <label for="created_time" >Created</label>
            <input type="text" v-model="template.created" class="w-100" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="Created Time" id="created_time">
          </div>
          <div class="col-4">
            <label for="owner_id" >Owner</label>
            <input type="text" v-model="template.owner_id" class="w-100" @input="isDirty=true"
                   maxlength="256" name="owner" data-name="owner" placeholder="Owner Id" id="owner_id">
          </div>
          <div class="col-4">
            <label for="owner_id" >Is Public?</label>
            <b-form-checkbox
                :checked="template.is_public"
                aria-describedby="devices"
                aria-controls="devices"
                @change="changeIsPublic"
            >
              Is Public?
            </b-form-checkbox>
          </div>
          <div class="col-4 flex-row">
            <label for="owner_id" >Icon</label>
            <b-input-group>
              <IconSelector v-model="template.icon" :color="template.color" @input="isDirty=true"></IconSelector>
              <input v-model="template.color" class="flex-grow-1" type="color" @input="isDirty=true"
                     maxlength="256" name="owner" data-name="owner" placeholder="Color" id="owner_id">
            </b-input-group>
          </div>

          <div class="col-4">
            <label for="owner_id" >Valid Related Types</label>
            <b-dropdown :text="relatedTypesButtonText" class="w-100">
              <b-check :checked="validRelatedTypes.length === 0" @change="validRelatedTypes = []">
                Not Set (Any/None)
              </b-check>
              <b-check v-for="(type, idx) of validRelatedTypeOptions" v-bind:key="idx"
                  :checked="validRelatedTypes.includes(type.value)"
                  @change="setValidTypes(type.value, $event)"
              >
                {{type.text}}
              </b-check>
<!--              <b-check-group :options="validRelatedTypeOptions" v-model="template.valid_related_types"></b-check-group>-->
            </b-dropdown>
          </div>

          <div class="col-12" :class="{'expand-section': expandSchema}">
            <div class="flex-row justify-content-between">
              <div class="flex-row justify-content-center">
                <label for="sim_iccid">JSON Schema</label>
                <div v-if="!isSchemaValid" class="invalid-warning">Invalid JSON <i class="fa fa-exclamation-triangle"></i></div>
              </div>
              <i class="fa icon-button" :class="{'fa-expand': !expandSchema, 'fa-compress': expandSchema}" @click="expandSchema = !expandSchema"></i>
            </div>
            <div class="schema-editor" :class="{'invalid-data': !isSchemaValid, 'expand-editor': expandSchema}">
              <codemirror ref="jsonEditor" v-model="jsonSchemaText" :options="cmOptions" @input="changeTemplate"
              ></codemirror>
            </div>
          </div>
          <div class="col-12" :class="{'expand-section': expandUISchema}">
            <div class="flex-row justify-content-between">
              <div class="flex-row justify-content-center">
                <label for="sim_iccid">UI Schema</label>
                <div v-if="!isUISchemaValid" class="invalid-warning">Invalid JSON <i class="fa fa-exclamation-triangle"></i></div>
              </div>
              <i class="fa icon-button" :class="{'fa-expand': !expandUISchema, 'fa-compress': expandUISchema}" @click="expandUISchema = !expandUISchema"></i>
            </div>
            <div class="schema-editor" :class="{'invalid-data': !isUISchemaValid, 'expand-editor': expandUISchema}">
              <codemirror ref="uiEditor" v-model="uiSchemaText" :options="cmOptions" @input="changeTemplate">
              </codemirror>
            </div>
          </div>
          <div class="col-12" :class="{'expand-section': expandWorkflowSchema}">
            <div class="flex-row justify-content-between">
              <div class="flex-row justify-content-center">
                <label for="sim_iccid">Workflow Schema</label>
                <div v-if="!isWorkflowSchemaValid" class="invalid-warning">Invalid JSON <i class="fa fa-exclamation-triangle"></i></div>
              </div>
              <i class="fa icon-button" :class="{'fa-expand': !expandWorkflowSchema, 'fa-compress': expandWorkflowSchema}" @click="expandWorkflowSchema = !expandWorkflowSchema"></i>
            </div>
            <div class="schema-editor" :class="{'invalid-data': !isWorkflowSchemaValid, 'expand-editor': expandWorkflowSchema}">
              <codemirror ref="uiEditor" v-model="workflowSchemaText" :options="cmOptions" @input="changeTemplate">
              </codemirror>
            </div>
          </div>
          <div class="spacing"></div>
        </div>
    <div class="flex-row justify-content-end mt-2">
      <button class="warning" v-show="isDirty" @click="revertChanges">Revert Changes</button>
      <button :disabled="!isDirty || !isUISchemaValid || !isSchemaValid || !isWorkflowSchemaValid" @click="clickSave">Save</button>
    </div>

  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import * as AlertHelper from '../helpers/AlertHelper'
import {faIcons} from '@/components/helpers/IconHelper'
import FormTemplate from '@/components/forms/FormTemplate.vue'
// CODEMIRROR IMPORTS
import 'codemirror/lib/codemirror.css'
// language
import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/mode/javascript/javascript'
// theme css
import 'codemirror/theme/monokai.css'
// require active-line.js
// styleSelectedText
import 'codemirror/addon/selection/mark-selection.js'
import 'codemirror/addon/search/searchcursor.js'
// hint
import 'codemirror/addon/hint/show-hint.js'
import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/addon/hint/javascript-hint.js'
import 'codemirror/addon/selection/active-line.js'
// highlightSelectionMatches
import 'codemirror/addon/scroll/annotatescrollbar.js'
import 'codemirror/addon/search/matchesonscrollbar.js'
import 'codemirror/addon/search/match-highlighter.js'
// keyMap
import 'codemirror/mode/clike/clike.js'
import 'codemirror/addon/edit/matchbrackets.js'
import 'codemirror/addon/comment/comment.js'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/search/search.js'
import 'codemirror/keymap/sublime.js'
// foldGutter
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/brace-fold.js'
import 'codemirror/addon/fold/comment-fold.js'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/indent-fold.js'
import 'codemirror/addon/fold/markdown-fold.js'
import 'codemirror/addon/fold/xml-fold.js'
import IconSelector from '@/components/shared/IconSelector.vue'

// --------
export default {
  name: 'admin-edit-template',
  components: {
    IconSelector,
    FormTemplate
  },
  props: {
    template_id: [Number, String]
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      template: {
      },
      statusOptions: [
        { value: '0', text: 'Issued' },
        { value: '1', text: 'Active' },
        { value: '2', text: 'Inactive' },
        { value: '3', text: 'Error' }
      ],
      iconFilter: '',
      iconOptions: faIcons,
      savedTemplate: {},
      savedJsonSchema: '',
      savedUISchema: '',
      savedWorkflowSchema: '',
      jsonSchemaText: '',
      uiSchemaText: '',
      workflowSchemaText: '',
      cmOptions: {
        tabSize: 4,
        styleActiveLine: false,
        lineNumbers: true,
        styleSelectedText: false,
        line: true,
        foldGutter: false,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        mode: 'text/javascript',
        // hint.js options
        hintOptions: {
          completeSingle: false
        },
        keyMap: 'sublime',
        matchBrackets: true,
        showCursorWhenSelecting: true,
        theme: 'monokai',
        extraKeys: {
          'Ctrl': 'autocomplete',
          'Ctrl-S': () => this.clickSave()
        }
      },
      expandSchema: false,
      expandUISchema: false,
      expandWorkflowSchema: false,
      isDirty: false,
      isSchemaDirty: false,
      validRelatedTypeOptions: [
        {
          value: 'device',
          text: 'Device'
        },
        {
          value: 'trip',
          text: 'Trips'
        },
        {
          value: 'user',
          text: 'User'
        }
      ],
      validRelatedTypes: [],
      savedValidRelatedTypes: null
    }
  },
  async created () {
    if (this.template_id === 'new') {
      this.loadTemplateData({
        template_id: 'NEW',
        color: '#FFFFFF',
        is_public: false,
        owner_id: null,
        valid_related_types: null,
        schema: {
          json_schema: {},
          ui_schema: {},
          workflow: {}
        }
      })
      this.setSavedTemplate(this.template)
    } else if (this.template_id) {
      this.loadTemplateData(await this.getTemplate(this.template_id))
      this.setSavedTemplate(this.template)
    } else {
      ErrorHelper.displayGeneralErrorToast('Template ID was not provided to Template Editor.',
        'No Template Id')
    }
    this.loading = false
  },
  methods: {
    async getTemplate (templateId) {
      let resp = await DataProvider.getTemplate(templateId)
      console.log('resp": ', resp)
      if (resp.success) {
        return resp.data
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    loadTemplateData(newTemplate) {
      console.log('Loading Template: ', newTemplate)
      this.template = {...newTemplate}
      this.jsonSchemaText = JSON.stringify(newTemplate.schema.json_schema, null, '\t')
      this.uiSchemaText = JSON.stringify(newTemplate.schema.ui_schema, null, '\t')
      this.workflowSchemaText = JSON.stringify(newTemplate.schema.workflow, null, '\t')
      this.validRelatedTypes = this.template.valid_related_types ? this.template.valid_related_types.split(',') : []
    },
    setSavedTemplate(template) {
      this.savedTemplate = {...template}
      this.savedJsonSchema = JSON.stringify(template.schema.json_schema, null, '\t')
      this.savedUISchema = JSON.stringify(template.schema.ui_schema, null, '\t')
      this.savedWorkflowSchema = JSON.stringify(template.schema.workflow, null, '\t')
      this.savedValidRelatedTypes = this.validRelatedTypes
      this.isSchemaDirty = false
      this.isDirty = false
    },
    changeIsPublic (newVal) {
      this.isDirty = true
      this.template.is_public = newVal
    },
    changeTemplate() {
      this.isDirty = true
      this.isSchemaDirty = true
      console.log('CHANGE')
      try {
        this.template.schema.json_schema = JSON.parse(this.jsonSchemaText)
      } catch (e) {
        // This is fine
      }

      try {
        this.template.schema.ui_schema = JSON.parse(this.uiSchemaText)
      } catch (e) {
        // This is fine
      }

      try {
        this.template.schema.workflow = JSON.parse(this.workflowSchemaText)
      } catch (e) {
        // This is fine
      }
    },
    revertChanges () {
      console.log('Reverting...', this.savedTemplate)
      this.loadTemplateData(this.savedTemplate)
      this.jsonSchemaText = this.savedJsonSchema
      this.uiSchemaText = this.savedUISchema
      this.workflowSchemaText = this.savedWorkflowSchema
      this.validRelatedTypes = this.savedValidRelatedTypes
      this.isDirty = false
      this.isSchemaDirty = false
    },
    clickSave() {
      if (this.template.template_id === 'NEW') {
        this.createTemplate()
      } else {
        this.updateTemplate()
      }
    },
    getValidTypeString() {
      if (this.validRelatedTypes.length) {
        return this.validRelatedTypes.join(',')
      } else {
        return null
      }
    },
    getSaveTemplateObject(isNew = false) {
      let saveTemplate = {...this.template}
      if (isNew) {
        saveTemplate.template_id = null
      }
      delete saveTemplate.created
      saveTemplate.valid_related_types = this.getValidTypeString()
      if (saveTemplate.owner_id === '') {
        saveTemplate.owner_id = null
      }
      return saveTemplate
    },
    async createTemplate() {
      console.log('Creating Template: ', this.template)
      let saveTemplate = this.getSaveTemplateObject(true)
      let resp = await DataProvider.createTemplate(saveTemplate)
      console.log('Response: ', resp)
      if (resp.success) {
        this.loadTemplateData(resp.data.template)
        this.setSavedTemplate(resp.data.template)
        AlertHelper.successToast('Template Created Successfully!', 'Template Created')
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    async updateTemplate() {
      console.log('Updating Template: ', this.template)
      let saveTemplate = this.getSaveTemplateObject(false)
      if (!this.isSchemaDirty) {
        delete saveTemplate['schema']
      }

      let resp = await DataProvider.updateTemplate(saveTemplate)
      console.log('Response: ', resp)
      if (resp.success) {
        this.loadTemplateData(resp.data.template)
        this.setSavedTemplate(resp.data.template)
        AlertHelper.successToast('Template Saved Successfully!', 'Template Saved')
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    setValidTypes(type, $event) {
      console.log(type, $event)
      console.log(this.template.valid_related_types)
      this.isDirty = true

      if ($event) {
        this.validRelatedTypes.push(type)
      } else {
        this.validRelatedTypes = this.validRelatedTypes.filter(x => x !== type)
      }
    }
  },
  computed: {
    relatedTypesButtonText () {
      return this.template.valid_related_types ? this.template.valid_related_types.toString() : 'Not Set (Any)'
    },
    isSchemaValid () {
      if (this.jsonSchemaText) {
        try {
          JSON.parse(this.jsonSchemaText)
          return true
        } catch (e) {
          return false
        }
      }
    },
    isUISchemaValid () {
      if (this.uiSchemaText) {
        try {
          JSON.parse(this.uiSchemaText)
          return true
        } catch (e) {
          return false
        }
      }
    },
    isWorkflowSchemaValid () {
      if (this.workflowSchemaText) {
        try {
          JSON.parse(this.workflowSchemaText)
          return true
        } catch (e) {
          return false
        }
      }
    },
    filteredIconOptions () {
      if (this.iconFilter !== '') {
        return faIcons.filter(x => x.includes(this.iconFilter))
      } else {
        return faIcons
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .admin-edit-template {
    color: $text-color-invert;
    padding: 5px;
  }

  .device-label{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .device-code{
    color: $text-color-invert;
    margin-left: 5%;
  }

  .toolbar {
    display: flex;
    flex-direction: row;
  }

  .icon-button, .icon-button-danger{
    font-size: 1.9rem;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

  .notification-container {
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    overflow-y: auto;
  }

  .notification-item{
    background: $theme-color-background-1;
  }

  .b-dropdown-form {
    font-size: 1.1em;
    color: $theme-color-primary-3;
  }

  .b-dropdown-form:hover {
    color: white;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(56, 212, 41, 0.25);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  .invalid-data {
    outline: 1px solid red;
  }

  .invalid-warning {
    color: #cc9c00;
  }

  .vue-codemirror {
    text-align: start;
  }

  .schema-editor {
    height: 300px;
    overflow: auto;
  }

  .expand-section {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100vw - 61px);
    height: 100vh;
    z-index: 50;
    background: $theme-color-background-2;
    overflow: auto;
    padding-bottom: 2em;
  }

  .expand-editor {
    height: auto;
  }

  .preview-container {
    margin: 2em auto;
    background: $theme-color-background-2;
    border-radius: 25px;
    max-width: 680px;
    padding: 40px;
  }

</style>
