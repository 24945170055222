<template>
  <div class="admin-template-edit">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
      <div>

      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Template Edit</h2></b-card-header>
        <b-card-body class="template-body">
          <admin-edit-template :template_id="templateId" v-if="templateId"></admin-edit-template>
        </b-card-body>
      </b-card>
    </div>
    <!--    Create Edit Modal Template-->
<!--    <b-modal id="modal-edit-product" centered class="modal-content" size="xl" hide-footer title="Edit Product">-->
<!--      <admin-edit-product is-modal modal="modal-edit-product" v-on:save="refreshList()"></admin-edit-product>-->
<!--    </b-modal>-->
  </div>
</template>

<script>


import AdminEditTemplate from '@/components/admin/AdminEditTemplate.vue'

export default {
  name: 'admin-templates',
  components: {
    AdminEditTemplate,
  },
  data: function () {
    return {
      templateId: null
    }
  },
  mounted() {
    if (Object.hasOwn(this.$route.params, 'template_id')) {
      console.log('Template_id : ', this.$route.params.template_id)
      this.templateId = this.$route.params.template_id
    }
  },
  methods: {
    clickBack: function () {
      this.$router.back()
    },
    selectChange: function (newSelection) {
      this.selectedUsers = newSelection
    },
    createTemplate: function () {
      this.$bvModal.show('modal-create-user')
    },
    refreshList: function () {
      this.$refs.adminProductList.refresh()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-task {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }

  .template-body {
    overflow: auto;
    max-height: 85vh;
  }

</style>
