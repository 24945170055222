var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", { staticClass: "admin-edit-template" }, [
        _c(
          "div",
          { staticClass: "preview-container" },
          [
            _c("h3", [_vm._v("Template Preview")]),
            _c("div", { staticClass: "green-divider" }),
            _c("FormTemplate", { attrs: { template: _vm.template } })
          ],
          1
        ),
        _c("div", { staticClass: "flex-row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("label", { attrs: { for: "name" } }, [_vm._v("Name")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.template.name,
                  expression: "template.name"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "text",
                maxlength: "256",
                name: "name",
                "data-name": "name",
                placeholder: "Template Name",
                id: "name"
              },
              domProps: { value: _vm.template.name },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.template, "name", $event.target.value)
                  },
                  function($event) {
                    _vm.isDirty = true
                  }
                ]
              }
            })
          ]),
          _c("div", { staticClass: "col-12" }, [
            _c("label", { attrs: { for: "description" } }, [
              _vm._v("Description")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.template.description,
                  expression: "template.description"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "text",
                maxlength: "256",
                name: "description",
                "data-name": "description",
                placeholder: "Template Description",
                id: "description"
              },
              domProps: { value: _vm.template.description },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.template, "description", $event.target.value)
                  },
                  function($event) {
                    _vm.isDirty = true
                  }
                ]
              }
            })
          ]),
          _c("div", { staticClass: "col-4 s4" }, [
            _c("label", { attrs: { for: "template_id" } }, [
              _vm._v("Template ID")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.template.template_id,
                  expression: "template.template_id"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                placeholder: "Template Id",
                id: "template_id"
              },
              domProps: { value: _vm.template.template_id },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.template, "template_id", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "col-4 s4" }, [
            _c("label", { attrs: { for: "rev_number" } }, [
              _vm._v("Revision Number")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.template.revision_number,
                  expression: "template.revision_number"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                placeholder: "Revision Number",
                id: "rev_number"
              },
              domProps: { value: _vm.template.revision_number },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.template, "revision_number", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { attrs: { for: "created_time" } }, [
              _vm._v("Created")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.template.created,
                  expression: "template.created"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                name: "Registration",
                "data-name": "Registration",
                placeholder: "Created Time",
                id: "created_time"
              },
              domProps: { value: _vm.template.created },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.template, "created", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { attrs: { for: "owner_id" } }, [_vm._v("Owner")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.template.owner_id,
                  expression: "template.owner_id"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "text",
                maxlength: "256",
                name: "owner",
                "data-name": "owner",
                placeholder: "Owner Id",
                id: "owner_id"
              },
              domProps: { value: _vm.template.owner_id },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.template, "owner_id", $event.target.value)
                  },
                  function($event) {
                    _vm.isDirty = true
                  }
                ]
              }
            })
          ]),
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c("label", { attrs: { for: "owner_id" } }, [
                _vm._v("Is Public?")
              ]),
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    checked: _vm.template.is_public,
                    "aria-describedby": "devices",
                    "aria-controls": "devices"
                  },
                  on: { change: _vm.changeIsPublic }
                },
                [_vm._v(" Is Public? ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-4 flex-row" },
            [
              _c("label", { attrs: { for: "owner_id" } }, [_vm._v("Icon")]),
              _c(
                "b-input-group",
                [
                  _c("IconSelector", {
                    attrs: { color: _vm.template.color },
                    on: {
                      input: function($event) {
                        _vm.isDirty = true
                      }
                    },
                    model: {
                      value: _vm.template.icon,
                      callback: function($$v) {
                        _vm.$set(_vm.template, "icon", $$v)
                      },
                      expression: "template.icon"
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.template.color,
                        expression: "template.color"
                      }
                    ],
                    staticClass: "flex-grow-1",
                    attrs: {
                      type: "color",
                      maxlength: "256",
                      name: "owner",
                      "data-name": "owner",
                      placeholder: "Color",
                      id: "owner_id"
                    },
                    domProps: { value: _vm.template.color },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.template, "color", $event.target.value)
                        },
                        function($event) {
                          _vm.isDirty = true
                        }
                      ]
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c("label", { attrs: { for: "owner_id" } }, [
                _vm._v("Valid Related Types")
              ]),
              _c(
                "b-dropdown",
                {
                  staticClass: "w-100",
                  attrs: { text: _vm.relatedTypesButtonText }
                },
                [
                  _c(
                    "b-check",
                    {
                      attrs: { checked: _vm.validRelatedTypes.length === 0 },
                      on: {
                        change: function($event) {
                          _vm.validRelatedTypes = []
                        }
                      }
                    },
                    [_vm._v(" Not Set (Any/None) ")]
                  ),
                  _vm._l(_vm.validRelatedTypeOptions, function(type, idx) {
                    return _c(
                      "b-check",
                      {
                        key: idx,
                        attrs: {
                          checked: _vm.validRelatedTypes.includes(type.value)
                        },
                        on: {
                          change: function($event) {
                            return _vm.setValidTypes(type.value, $event)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(type.text) + " ")]
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-12",
              class: { "expand-section": _vm.expandSchema }
            },
            [
              _c("div", { staticClass: "flex-row justify-content-between" }, [
                _c("div", { staticClass: "flex-row justify-content-center" }, [
                  _c("label", { attrs: { for: "sim_iccid" } }, [
                    _vm._v("JSON Schema")
                  ]),
                  !_vm.isSchemaValid
                    ? _c("div", { staticClass: "invalid-warning" }, [
                        _vm._v("Invalid JSON "),
                        _c("i", { staticClass: "fa fa-exclamation-triangle" })
                      ])
                    : _vm._e()
                ]),
                _c("i", {
                  staticClass: "fa icon-button",
                  class: {
                    "fa-expand": !_vm.expandSchema,
                    "fa-compress": _vm.expandSchema
                  },
                  on: {
                    click: function($event) {
                      _vm.expandSchema = !_vm.expandSchema
                    }
                  }
                })
              ]),
              _c(
                "div",
                {
                  staticClass: "schema-editor",
                  class: {
                    "invalid-data": !_vm.isSchemaValid,
                    "expand-editor": _vm.expandSchema
                  }
                },
                [
                  _c("codemirror", {
                    ref: "jsonEditor",
                    attrs: { options: _vm.cmOptions },
                    on: { input: _vm.changeTemplate },
                    model: {
                      value: _vm.jsonSchemaText,
                      callback: function($$v) {
                        _vm.jsonSchemaText = $$v
                      },
                      expression: "jsonSchemaText"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "col-12",
              class: { "expand-section": _vm.expandUISchema }
            },
            [
              _c("div", { staticClass: "flex-row justify-content-between" }, [
                _c("div", { staticClass: "flex-row justify-content-center" }, [
                  _c("label", { attrs: { for: "sim_iccid" } }, [
                    _vm._v("UI Schema")
                  ]),
                  !_vm.isUISchemaValid
                    ? _c("div", { staticClass: "invalid-warning" }, [
                        _vm._v("Invalid JSON "),
                        _c("i", { staticClass: "fa fa-exclamation-triangle" })
                      ])
                    : _vm._e()
                ]),
                _c("i", {
                  staticClass: "fa icon-button",
                  class: {
                    "fa-expand": !_vm.expandUISchema,
                    "fa-compress": _vm.expandUISchema
                  },
                  on: {
                    click: function($event) {
                      _vm.expandUISchema = !_vm.expandUISchema
                    }
                  }
                })
              ]),
              _c(
                "div",
                {
                  staticClass: "schema-editor",
                  class: {
                    "invalid-data": !_vm.isUISchemaValid,
                    "expand-editor": _vm.expandUISchema
                  }
                },
                [
                  _c("codemirror", {
                    ref: "uiEditor",
                    attrs: { options: _vm.cmOptions },
                    on: { input: _vm.changeTemplate },
                    model: {
                      value: _vm.uiSchemaText,
                      callback: function($$v) {
                        _vm.uiSchemaText = $$v
                      },
                      expression: "uiSchemaText"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "col-12",
              class: { "expand-section": _vm.expandWorkflowSchema }
            },
            [
              _c("div", { staticClass: "flex-row justify-content-between" }, [
                _c("div", { staticClass: "flex-row justify-content-center" }, [
                  _c("label", { attrs: { for: "sim_iccid" } }, [
                    _vm._v("Workflow Schema")
                  ]),
                  !_vm.isWorkflowSchemaValid
                    ? _c("div", { staticClass: "invalid-warning" }, [
                        _vm._v("Invalid JSON "),
                        _c("i", { staticClass: "fa fa-exclamation-triangle" })
                      ])
                    : _vm._e()
                ]),
                _c("i", {
                  staticClass: "fa icon-button",
                  class: {
                    "fa-expand": !_vm.expandWorkflowSchema,
                    "fa-compress": _vm.expandWorkflowSchema
                  },
                  on: {
                    click: function($event) {
                      _vm.expandWorkflowSchema = !_vm.expandWorkflowSchema
                    }
                  }
                })
              ]),
              _c(
                "div",
                {
                  staticClass: "schema-editor",
                  class: {
                    "invalid-data": !_vm.isWorkflowSchemaValid,
                    "expand-editor": _vm.expandWorkflowSchema
                  }
                },
                [
                  _c("codemirror", {
                    ref: "uiEditor",
                    attrs: { options: _vm.cmOptions },
                    on: { input: _vm.changeTemplate },
                    model: {
                      value: _vm.workflowSchemaText,
                      callback: function($$v) {
                        _vm.workflowSchemaText = $$v
                      },
                      expression: "workflowSchemaText"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c("div", { staticClass: "spacing" })
        ]),
        _c("div", { staticClass: "flex-row justify-content-end mt-2" }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isDirty,
                  expression: "isDirty"
                }
              ],
              staticClass: "warning",
              on: { click: _vm.revertChanges }
            },
            [_vm._v("Revert Changes")]
          ),
          _c(
            "button",
            {
              attrs: {
                disabled:
                  !_vm.isDirty ||
                  !_vm.isUISchemaValid ||
                  !_vm.isSchemaValid ||
                  !_vm.isWorkflowSchemaValid
              },
              on: { click: _vm.clickSave }
            },
            [_vm._v("Save")]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }